import React from "react"

import Row from "./row"
import Spacer from "./spacer"
import Arrow from "./arrow"
import Image from "./image"

import l from "./layout.module.scss"

const Hero = ({ data }) => {
  const {
    hero_title,
    hero_subtitle,
    hero_text,
    hero_image,
    blocks_title,
  } = data
  return (
    <Row backgroundColor="#f2f2f2">
      <div className={[l.col1, l.title_section].join(" ")}>
        <h1 className={l.title}>{hero_title}</h1>
        <h3 className={l.sub_title}>{hero_subtitle}</h3>
      </div>
      <div className={l.section_grid_05}>
        <div>
          <div
            className={l.custom_content}
            dangerouslySetInnerHTML={{
              __html: hero_text,
            }}
          ></div>
        </div>
        <Image data={hero_image} />
      </div>
      <Spacer>
        <Arrow rotation="90deg" fontSize="18px" fontWeight="bold">
          {blocks_title}
        </Arrow>
      </Spacer>
    </Row>
  )
}

export default Hero
