import React from "react"
import { Link } from "gatsby"

import Arrow from "./arrow"

/* This component handles logic for determining whether to render <a> or <Link> */
const CTAButton = ({ data }) => {
  if (data.url || data.custom_url) {
    return data.custom_url ||
      data.url.includes("http") ||
      data.url.includes("mailto") ? (
      <a
        target={data.url.includes("mailto") ? "_self" : "_blank"}
        rel="noopener noreferrer"
        href={data.custom_url ? data.custom_url : data.url}
      >
        <Arrow fontSize="18px" fontWeight="bold">
          {data.label}
        </Arrow>
      </a>
    ) : data.label ? (
      <Link to={`${data.url}`}>
        <Arrow fontSize="18px" fontWeight="bold">
          {data.label}
        </Arrow>
      </Link>
    ) : null
  } else {
    return null
  }
}

export default CTAButton
