import React from "react"
import { Link } from "gatsby"

import l from "./layout.module.scss"

const Button = ({ type = { tag: "button" }, color, children }) => {
  if (type.tag !== "link") {
    const CustomTag = `${type.tag}`
    return (
      <CustomTag
        href={type.tag === "a" ? type.url : null}
        className={[l.button, l[color] || null].join(" ")}
      >
        {children}
      </CustomTag>
    )
  } else {
    return (
      <Link to={type.url} className={[l.button, l[color] || null].join(" ")}>
        {children}
      </Link>
    )
  }
}

export default Button
