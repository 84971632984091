import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Row from "../components/row"
import SectionTitle from "../components/sectionTitle"
import CTAButton from "../components/cta_button"
import Banner from "../components/banner"
import Image from "../components/image"

import a from "./alt_blocks.module.scss"
import l from "../components/layout.module.scss"

const AlternatingBlocksTemplate = ({ pageContext, data }) => {
  const {
    blocks,
    banner,
    ...hero
  } = data.wordpressPage.acf.subsidie.about_template

  return (
    <Layout>
      <SEO title={pageContext.title} />
      <Hero data={hero} />
      <Row>
        <SectionTitle title={hero.blocks_title} />
        <div className={a.blocks}>
          {blocks.map(b => (
            <section key={b.title} className={a.block}>
              <div className={a.content}>
                <h5 style={{ marginBottom: b.label ? "1rem" : "2rem" }}>
                  {b.title}
                </h5>
                <div
                  className={b.label ? l.above_link : l.text_block}
                  dangerouslySetInnerHTML={{ __html: b.text }}
                />
                <CTAButton data={b} />
              </div>
              <div className={a.img_wrapper}>
                <Image data={b.image} />
              </div>
            </section>
          ))}
        </div>
      </Row>
      <Row>
        <Banner data={banner} src="alt_blocks" />
      </Row>
    </Layout>
  )
}

export default AlternatingBlocksTemplate

// Find page data with wordpress_id passed in PageContext
export const query = graphql`
  query AlternatingBlocksTemplateQuery($wordpress_id: Int) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      acf {
        subsidie {
          about_template {
            hero_title
            hero_subtitle
            hero_text
            hero_image {
              ...HeroImage
            }
            blocks_title
            blocks {
              title
              text
              image {
                ...BlockImage
              }
              label
              url
              custom_url
            }
            banner {
              title
              subtitle
              label
              url
            }
          }
        }
      }
    }
  }
`
