import React from "react"

import Button from "./button"
import Arrow from "./arrow"

import l from "./layout.module.scss"

const Banner = ({ data, src = "post" }) => {
  return (
    <div className={l.banner}>
      <div>
        <div>
          <h5>{data.title}</h5>
          <p>{data.subtitle}</p>
        </div>
        <div className={l.button_container}>
          <Button type={{ tag: "a", url: data.url }} color="white">
            {data.label}
          </Button>
          <Button
            type={{
              tag: "a",
              url: "mailto:info@limach.nl?Subject=Demo aanvragen",
            }}
            color="green"
          >
            {src === "post" && (
              <Arrow fontSize="18px" fontWeight="bold">
                Demo aanvragen
              </Arrow>
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Banner
