import React from "react"

import l from "../components/layout.module.scss" // layout styles

const SectionTitle = ({ title }) => (
  <div className={[l.col1, l.section_title].join(" ")}>
    <h2 className={l.sub_title}>{title}</h2>
  </div>
)

export default SectionTitle
